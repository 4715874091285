import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import PageTitle from "../components/pageTitle"
import MailLink from "../components/mailLink"


const ContactPage = (props) => (
  <Layout location={props.location}>

  <Helmet>
    <title>Privacy Policy</title>
    <meta name="description" content="This Privacy Policy describes how your personal information is collected, used, and shared when you visit www.cdcarter.co.uk." />
  </Helmet>

  <section className="content">

    <div className="container">

      <PageTitle
        title="Privacy Policy"
        subtitle="The legal bit, for your peace of mind…"
      />

      <div className="content-main">
        <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit www.cdcarter.co.uk (the "Site").</p>

        <h3>Personal information we collect</h3>
        <p>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as "Device Information".</p>

        <p>We collect Device Information using the following technologies:</p>
        <ul className="content-list">
          <li>"Cookies" are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit <a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer" className="copy-link">www.allaboutcookies.org</a>.</li>
          <li>"Log files" track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
          <li>"Web beacons", "tags", and "pixels" are electronic files used to record information about how you browse the Site.</li>
          <li>Google Analytics</li>
        </ul>

        <p>Additionally when you make a call back request through the Site, we collect certain information from you, including your name, email address, and phone number. We refer to this information as "Call Back Information".</p>

        <p>When we talk about "Personal Information" in this Privacy Policy, we are talking both about Device Information and Call Back Information.</p>

        <h3>How do we use your Personal Information?</h3>
        <p>We use the Call Back Information that we collect to:</p>
        <ul className="content-list">
          <li>Communicate with you; and</li>
          <li>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
        </ul>

        <p>We use the Device Information that we collect to generally improve and optimise our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p>

        <h3>Sharing your Personal Information</h3>
        <p>We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we also use Google Analytics to help us understand how our customers use the Site -- you can read more about how Google uses your Personal Information here: <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer" className="copy-link">www.google.com/intl/en/policies/privacy/</a>. You can also opt-out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer" className="copy-link">tools.google.com/dlpage/gaoptout</a>.</p>

        <p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, other lawful request for information we receive, or to otherwise protect our rights.</p>

        <h3>Do not track</h3>
        <p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>

        <h3>Your rights</h3>
        <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>

        <h3>Changes</h3>
        <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>

        <h3>Contact us</h3>
        <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e‑mail at <MailLink href="mailto:hello@cdcarter.co.uk">hello@cdcarter.co.uk</MailLink>.</p>




      </div>

    </div>

  </section>

  </Layout>
)

export default ContactPage
